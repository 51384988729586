import _ from 'lodash';
import page from 'page';

import searchPageContent from 'bundle-text:./pages/search.html';

const appElem = document.querySelector('#app');

page('/', async () => {
  await fetchAndRender(searchPageContent);
  const { init } = await import('./pages/search.js');
  init();
});

page('/ulubione', async () => {
  await fetchAndRender(import('./pages/favorites.html'));
  const { init } = await import('./pages/favorites.js');
  init();
});

page('/wydarzenie/:eventId', async (ctx) => {
  await fetchAndRender(import('./pages/event.html'));
  const { init } = await import('./pages/event.js');
  init(ctx.params.eventId);
});

page();


async function fetchAndRender(htmlFile) {
  console.log('router :: fetching');
  const content = htmlFile instanceof Promise ? await htmlFile : htmlFile;
  let htmlString;
  if (_.isArray(content)) {
    htmlString = content.filter(x => !!x).join('\n');
  } else if (content.startsWith('http')) {
    htmlString = await (await fetch(content)).text();
  } else {
    htmlString = content;
  }
  console.log('router :: rendering');
  appElem.innerHTML = htmlString;
}
